import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import Layout from '../components/layout'
import Seo from '../components/seo'

const Freelance = () => (
  <Layout>
    <Seo title="Freelance" />
    <div className="container">
      <div className="about-hero">
        <StaticImage
          src="../images/logo.svg"
          alt="BM Creative Works"
          className="menu-logo"
        />
      </div>
      <div className="container" style={{ marginTop: '50px' }}>
        <div className="freelance-page">
          <h2>Selam biz BM Creative,</h2>
          <p>
            Ama bu yazı bizimle ilgili değil, senin hakkında konuşalım. Biraz
            yardımına ihtiyacımız var. Bazen ekstra bir göz, farklı düşünen bir
            beyin ve pratik çalışan ellere ihtiyaç duyuyoruz. İş deneyimi mi?
            Baktığımız şey tam olarak bu değil. Tecrübe sahibi olmanın çok
            değerli olduğuna kesinlikle inanıyoruz ama fikirlerin ve becerilerin
            daha değerli olduğu esas odak noktamız!
          </p>
          <p>
            Madem buradasın, çalışmalarımızı görmüş olmalısın. İyi işler
            çıkarıyoruz değil mi? Biz de öyle düşünüyoruz!
          </p>
          <h3>İlgini çekti mi?</h3>
          <p>
            Her zaman freelancer ağımızı genişletmek istiyoruz. Bu şekilde en
            iyi insanlarla farklı zorlukların üstesinden gelebileceğimize
            inanıyoruz. Yeni tasarımcılar, illüstratörler, animatörler,
            geliştiriciler, fotoğrafçılar, metin yazarları ve daha fazlasıyla
            ağımızı her zaman genişletmek istiyoruz. Bizimle proje bazlı
            çalışmak istiyorsan bize mesaj göndermen yeterli.
          </p>
          <h3 className="freelance-mail">
            <a href="mailto:business@bmcreative.works?subject=Freelancer">
              business@bmcreative.works
            </a>{' '}
            <span style={{ fontWeight: '100' }}>mesajını bekliyoruz.</span>
          </h3>
        </div>
      </div>
    </div>
  </Layout>
)

export default Freelance
